import { useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import RAList from 'components/common/RAList';
import { useMemo } from 'react';
import {
    BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  NumberInput,
  SearchInput,
  SimpleList,
  TextField,
  UrlField,
} from 'react-admin';
import dateUtils from 'utils/date';
//import GameIdFilter from './GameIdFilter';
//import PlayerBoyIdFilter from './PlayerBoyIdFilter';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '16px',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      padding: '16px 0',
    },
  },
}));

export const TransactonFailedList = (props: any) => {
  const { classes } = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  

  const datagrid = useMemo(() => {
    if (matches) {
      return (
        <Datagrid rowClick="show" optimized>
          <TextField source="player_nickname" label="Nickname" />
          <TextField source="wallet_public_key" label="wallet" />
          <TextField source="amount" />
          <TextField source="unit_symbol" label="unit" />
          <TextField source="kind" />
          {<DateField source="purchased_at" />}
          <FunctionField
                source="data" label='refund link'
                render={record => {
                    if (record.data.refund_txn_sig) {
                      const { REACT_APP_ENV } = process.env;
                      return (
                        <a href={`https://solscan.io/tx/${record.data.refund_txn_sig}${REACT_APP_ENV === "dev" ? "?cluster=devnet" : ''}`} style={{color : "red"}} >
                            {"link"}
                        </a>
                      );
                    } else {
                      return  null;
                    }

                } }
            />
          <BooleanField source="refunded" />
          {<DateField source="refunded_at" />}
          <BooleanField source="reclaimed" />
          {<DateField source="reclaimed_at" />}
        </Datagrid>
      );
    }
    return (
      <SimpleList
        linkType={'show'}
        primaryText={(record) => record.player_id}
        secondaryText={(record) => record.player_nickname}
        tertiaryText={(record) => record.refunded
          //dateUtils.localizedFormat(new Date(record.subscription_date), 'Ppp')
        }
      />
    );
  }, [matches]);
  return (
    <RAList
    disableSyncWithLocation
      className={classes.root}
      exporter={false}
      bulkActionButtons={false}
      perPage={25}
      total={10}
      {...props}>
      {datagrid}
    </RAList>
  );
};

export default TransactonFailedList;
